<template>
  <v-btn
    :class="totalUnreadComments && 'sourcery__has-icon'"
    class="white--text"
    color="red"
    elevation="0">
    <span
      v-if="totalUnreadComments"
      class="sourcery__icon-wrapper white red--text">
      {{ totalUnreadComments }}
    </span>
    {{ btnName }}
  </v-btn>
</template>
<script>
export default {
  name: 'AppSummaryCommentsBtn',
  props: {
    totalUnreadComments: {
      type: Number,
      default: 0,
    },
    btnName: {
      type: String,
      default: 'Collection Chat',
    },
  },
};
</script>
